@import "~antd/dist/antd.min.css";
@import "./fonts/Montserrat/stylesheet.css";

html {
    min-width: 320px;
}

* {
    font-family: Montserrat, sans-serif;
    font-weight: 500;
}

#root {
    height: 100%;
}